import React,  { FC, useContext, useState, ChangeEvent, FormEvent } from 'react';
import { Container, Input, Row, Col, Button, Form, FormGroup, Alert } from 'reactstrap';
import './LoginComponent.css';
import AuthStore from '../../stores/AuthStore';
import AuthService from '../../services/AuthService';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import IUser from '../../entities/User';

const LoginComponent : FC = () => {
    
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const authStore = useContext(AuthStore);
    const authService = new AuthService();
    const history = useHistory();
    const [errormessage, setErrormessage] = useState('')

    const submitLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('submitted: ' + email + '-' + password);
        authService.login(email, password).then((user: IUser) => {
            setTimeout(() => {
                authStore.setUser({...user});
                if(user.roles.includes('Administrator') || user.roles.includes('Manager')){      
                    history.push('/workflows');
                }
                else{
                    history.push('/myworkflows');
                }
            }, 200);
        }).catch(e => {
            setErrormessage(e.response.data.errors);
            setTimeout(() => {
                setErrormessage('');
            }, 3000);
        });
    };

    return (
        <Container fluid className="loginPage">
            <Row className="">
                <Col md="4"  style={{ height: '84.9%', paddingTop: '12vh', paddingLeft: '10%'}}>
                    <div className="mt-auto">
                        <h4 className="text-center" style={{marginBottom: '40px'}}>
                            <img style={{ width: '90%' }}
                                alt={'logo'}
                                src={'images/prasinotameio-logo-01.png'}
                                />
                        </h4>
                        <h4 className="text-success text-center">
                            Πληροφοριακό Σύστημα Υποβολής Προτάσεων στα Προγράμματα του Πράσινου Ταμείου
                        </h4>
                        {
                            errormessage && 
                            <Alert color="danger">
                                {errormessage}
                            </Alert>
                        }
                        <Form onSubmit={submitLogin}>
                            <FormGroup>
                                <Input type='email' placeholder="Email"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.currentTarget.value) }} />
                            </FormGroup>
                            <FormGroup>
                                <Input type='password' placeholder="Password" 
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setPassword(e.currentTarget.value) }} />
                            </FormGroup>
                            <Button color="primary" className="btn-block">Login</Button>
                        </Form>
                        <p className="small" style={{fontSize: '10px'}}>Αν έχετε πρόβλημα με την πρόσβαση στο νέο σύστημα επικοινωνήστε με την Evolution Projects στα mail: 
                            <a href="mailto:support@evolutionprojects.gr">support@evolutionprojects.gr</a> και <a href="mailto:ekolaitis@evolutionprojects.gr">ekolaitis@evolutionprojects.gr</a></p>
                        <h4 className="text-center" style={{marginTop: '20px', color: '#888'}}>
                            <img
                                alt={'logo'}
                                className="logo-img"
                                src={'/images/logo.png'}
                                />
                                <br />
                                Developed by EvolutionProjects+
                        </h4>
                    </div>
                </Col>
                <Col md="8" >
                    
                </Col>
                <Col md="12" style={{padding: '5px'}} className="text-center mt-20">
                    <img src="images/login_page_footer.fw.png" style={{ width: '60%' }} alt="img"/>
                </Col>
            </Row>
            {/* <object style={{width: '100%', height: '100vh'}} data="https://checkout-inart.thinkplus.dev/1fb476bb-61be-43fc-9f5c-3e52f062aeeb" /> */}
        </Container>
    );
};

export default observer(LoginComponent);